import moment from "moment";

export const DateHeader = ({
  date,
  onHeaderClick,
  headerFormat = "dd, DD.MM",
}) => {
  const dayName = moment(date, "DD-MM-YYYY").locale("de").format(headerFormat);
  return (
    <div className="header" onClick={() => onHeaderClick?.(date)}>
      <span className="header-title">{dayName}</span>
    </div>
  );
};
