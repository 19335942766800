import React from 'react';
import { Nav, IconButton, ButtonGroup } from 'rsuite';
import {
  RoomTabContent,
  FerryTabContent,
  ServiceTabContent,
} from './offer-search-tabs/index';
import { ItineraryTabContent } from './offer-search-tabs/itinerary-tab-content';
import { FlightTabContent } from './offer-search-tabs/flight-tab-content';

export const TAB_KEY = {
  ROOM: 'tourism-accommodation/accommodation-interface',
  FERRY: 'tourism-ferry/ferry',
  SERVICE: 'tourism-service/service',
  SHIP: 'tourism-ship/ship',
  ITINERARY: 'tourism/itinerary-splitted',
  FLIGHT: 'tourism-flight/fare-search',
};
export const TABS = {
  [TAB_KEY.ROOM]: {
    icon: 'fa fa-hotel',
    title: 'Hotel',
    component: RoomTabContent,
  },
  [TAB_KEY.FERRY]: {
    title: 'Fähre',
    icon: 'fa fa-ferry',
    component: FerryTabContent,
  },
  [TAB_KEY.SHIP]: {
    title: 'Kreuzfahrt',
    icon: 'fa fa-ship',
    component: FerryTabContent,
  },
  [TAB_KEY.SERVICE]: {
    icon: 'fa fa-ticket',
    title: 'Leistung',
    component: ServiceTabContent,
  },
  [TAB_KEY.ITINERARY]: {
    title: 'Reiseidee',
    icon: 'fa fa-globe',
    component: ItineraryTabContent,
  },
  [TAB_KEY.FLIGHT]: {
    title: 'Flug',
    icon: 'fa fa-plane',
    component: FlightTabContent,
  },
};

export const OfferSearchTabs = ({
  activeSearchTab,
  switchTab,
  showMap,
  handleShowMap,
}) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Nav
        appearance="subtle"
        activeKey={activeSearchTab}
        onSelect={switchTab}
        style={{ marginBottom: 10 }}
      >
        {Object.keys(TABS).map((tabKey) => (
          <Nav.Item key={tabKey} eventKey={tabKey}>
            <i className={TABS[tabKey].icon} title={TABS[tabKey].title}></i>
          </Nav.Item>
        ))}
      </Nav>
      <ButtonGroup>
        <IconButton
          icon={
            <i
              className={'fa fa-solid fa-map-location-dot'}
              title={'Karte'}
            ></i>
          }
          active={showMap}
          appearance={showMap ? 'primary' : 'default'}
          onClick={() => handleShowMap()}
        />
      </ButtonGroup>
    </div>
  );
};
