import React from "react";
import { Panel, Button, Divider } from "rsuite";
import { formatPriceWithCommas } from "../helper/utility";

export const OfferPreviewSummary = ({
    participants,
    selectedTimeAndPriceOption,
    onOrderConfirm,
    isSubmitDisabled,
}) => {
    const price = selectedTimeAndPriceOption?.price || 0;
    const formattedPrice = formatPriceWithCommas(
        price,
        selectedTimeAndPriceOption?.currency
    );

    return (
        <div className="preview-view-summary">
            <Panel bordered className="preview-view-summary__side-view">
                <div>
                    {participants} x {formattedPrice} pro Person
                </div>
            </Panel>
            <Button
                color="orange"
                appearance="primary"
                className="preview-view-summary__side-view"
                onClick={onOrderConfirm}
                disabled={isSubmitDisabled}
            >
                Hinzufügen
            </Button>
        </div>
    );
};
