import {bindable, customElement, inject, noView} from "aurelia-framework";
import {render, unmountComponentAtNode} from "react-dom";
import {Client} from "../../api/client";
import {CurrencyValueConverter} from "../../currency/currency-value-converter";
import React from "react";
import {UserClient} from "../../api/user-client";
import Calculation from "./calculation";

@noView
@inject(Element)
@customElement('sio-order-calculation')
export default class AureliaOffer {

    @bindable() order;

    constructor(container) {
        this.container = container;
    }

    attached() {
        this.render();
    }

    orderChanged()
    {
        this.detached();

        this.attached();
    }

    render() {
        render(
            <Calculation
                order={this.order}
            />,
            this.container
        );
    }

    detached() {
        unmountComponentAtNode(this.container);
    }
}
