import { useCallback } from "react";
import { VIEWS, VIEW_DURATION_DAYS } from "../constants/index";
import { ButtonToolbar, Button, ButtonGroup } from "rsuite";

const CALENDAR_NAV_BUTTONS = [
  { label: "<", action: "prev" },
  { label: ">", action: "next" },
];
export const DateControl = ({
  calendarView,
  activeView,
  onDateChange,
  changeCalendarView,
  selectedDate,
  offerStartDate,
  offerEndDate
}) => {
  const onDateNavBtnClick = useCallback(
    (action) => () => {
        if(action === "next" && (moment(new Date(selectedDate)) < moment(new Date(offerEndDate)))|| action === "prev" && (moment(new Date(selectedDate)) > moment(new Date(offerStartDate)))) {
            const api = calendarView?.view?.calendar;
            api.gotoDate(selectedDate.format("YYYY-MM-DD"));
            api[action]?.();
            onDateChange?.(api.getDate());
        } else {
            return;
        }
    },
    [onDateChange, calendarView, selectedDate]
  );

  return (
    <div className="days-controls">
      <div className="day-control">
        {CALENDAR_NAV_BUTTONS.map((btn) => (
          <Button
            key={btn.label}
            className="fc-button fc-button-primary fc-button"
            aria-pressed="false"
            title={btn.label}
            onClick={onDateNavBtnClick(btn.action)}
          >
            {btn.label}
          </Button>
        ))}
      </div>
      <ButtonToolbar>
        <ButtonGroup size="xs" className="day-control">
          {Object.keys(VIEWS).map((key) => (
            <Button
              key={key}
              onClick={() => {
                changeCalendarView(key);
              }}
              className="day-control-button"
              color={VIEWS[key] === activeView ? "blue" : undefined}
            >
              {VIEW_DURATION_DAYS[VIEWS[key]]}
            </Button>
          ))}
        </ButtonGroup>
      </ButtonToolbar>
    </div>
  );
};
