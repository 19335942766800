import {
  InputGroup,
  Input,
  Icon,
  Checkbox,
  SelectPicker,
  InputNumber,
} from "rsuite";
import { RADIUS_PICKER_VALUES } from "../../constants/index";
import { ChoiceElement } from "../../../../choice/react-choice-element";
import { GoogleOfferCityAutoComplete } from "../offer-city-autocomplete";
import { DateSelect } from "../../../../form/rsuite-form";
import { withGMapOptions } from "../../helper/gmap-context";
import { Foldable } from "../offer-foldable";
import SioIcon from "../../../../icon/rsuite-icon-font/SioIcon";

const CityAutoComplete = withGMapOptions(GoogleOfferCityAutoComplete);

export const ServiceTabContent = ({
  filters,
  searchFreeText,
  setSearchFreeText,
  setFilter,
  sortOptions,
  isNoPrice,
  setNoPrice,
}) => (
  <>
    <div className="col-xs-12 col-lg-8 col-small-padding">
      <div className="form-group">
        <label>Ort</label>
        <CityAutoComplete
          value={filters.city ?? ""}
          onSelect={(value) => {
            setFilter("city", value ? value : null);
          }}
        />
      </div>
    </div>

    <div className="radius-picker__container col-xs-12 col-lg-4 col-small-padding">
      <div className="form-group radius-picker">
        <label>Radius</label>
        <SelectPicker
          data={RADIUS_PICKER_VALUES}
          value={filters.radius}
          cleanable={false}
          onChange={(value) => {
            setFilter("radius", value ? value : null);
          }}
          className="select-picker-input"
        />
      </div>
    </div>

    <div className="col-xs-12 col-lg-6 col-small-padding">
      <div className="form-group">
        <label>Leistungstyp</label>
        <ChoiceElement
          config={{
            modelId: "tourism-service/service-category",
          }}
          placeholder="Wähle..."
          onChange={(value) => {
            setFilter("category", value ? { $eq: value } : null);
          }}
        />
      </div>
    </div>

    <div className="col-xs-12 col-lg-6 col-small-padding">
      <div className="form-group">
        <label>TAA-Tags</label>
        <ChoiceElement
          config={{
            modelId: "tourism-service/service-character",
          }}
          placeholder="Wähle..."
          onChange={(value) => {
            setFilter("serviceCharacter", value ? { $eq: value } : null);
          }}
        />
      </div>
    </div>

    <div className="form-group col-xs-12 col-lg-8 col-small-padding">
      <InputGroup inside className="offer-search-parameters_input">
        <Input
          value={searchFreeText}
          onChange={setSearchFreeText}
          placeholder={"Suchbegriff"}
        />
        <InputGroup.Button>
          <SioIcon icon="fa fa-search"/>
        </InputGroup.Button>
      </InputGroup>
    </div>
    <div className="form-group col-xs-12 col-lg-4 col-small-padding">
      <SelectPicker
        data={sortOptions}
        cleanable={false}
        value={filters.sort}
        searchable={sortOptions.length > 10}
        onChange={(value) => {
          setFilter("sort", value ? value : null);
        }}
        className="select-picker-input"
      />
    </div>
    <Foldable>

      <div className="col-xs-12">
        <Checkbox
          className="col-xs-12"
          checked={!!filters["alongTheRoute"]}
          onChange={(_, checked) => setFilter("alongTheRoute", checked)}
        >
          Entlang der Route
        </Checkbox>
      </div>

      <div className="form-group col-xs-6">
        <label>Dauer von (in h)</label>

        <Input
          value={filters.timeFrom}
          onChange={(d) => {
            setFilter("timeFrom", d);
          }}
        />
      </div>
      <div className="form-group col-xs-6">
        <label>Dauer bis (in h)</label>
        <Input
          value={filters.timeTo}
          onChange={(d) => {
            setFilter("timeTo", d);
          }}
        />
      </div>
      <div className="col-xs-12 form-group">
        <label>Dauer (an aufeinanderfolgenden Tagen)</label>
        <InputGroup className="offer-planning__number-picker">
          <InputNumber
            className="offer-planning__number-picker__input"
            value={filters.duration ?? 1}
            onChange={(value) => setFilter("duration", Number(value))}
            max={999999}
            min={1}
          />
        </InputGroup>
      </div>
      <div className="col-xs-6">
        <Checkbox
          checked={isNoPrice}
          onChange={(value, checked) => setNoPrice(checked)}
        >
          Ergebnisse ohne Preis anzeigen
        </Checkbox>
      </div>
    </Foldable>
  </>
);
