import { DialogController } from 'aurelia-dialog';
import { inject } from 'aurelia-framework';
import { Client } from '../api/client';
import {
    UnsavedChanges,
    UnsavedChangesPrevent,
} from '../form/unsaved-changes-prevent';
import { UnsavedChangesChecker } from '../form/unsaved-changes-checker';
import * as _ from 'lodash';
import './multi-product-choice.less';

@inject(DialogController, Client, UnsavedChangesChecker)
export class MultiProductChoice {
    constructor(dialogController, client, unsavedChangesChecker) {
        this.dialogController = dialogController;
        this.client = client;
        this.unsavedChangesChecker = unsavedChangesChecker;
    }

    activate(context) {
        this.title = context.title;
        this.order = context.order;
        this.itemsAndConfig = context.itemsAndConfig;
        this.originalUnsavedChanges = _.clone(UnsavedChanges.changes);
    }

    async submit(object) {
        UnsavedChangesPrevent.cleanChanges();
        const response = await this.client.patch(
            'order/order-item-add/trigger/' + this.order.id,
            object
        );
        if (response.ok && response.error === undefined) {
            const newItems = this.itemsAndConfig.map((item) => {
                if (item.item.id === object.product.id) {
                    return {
                        ...item,
                        formState: response.ok ? 'success' : 'failed',
                    };
                } else {
                    return {
                        ...item,
                        formState: item.formState,
                    };
                }
            });

            this.itemsAndConfig = newItems;
        } else {
            throw new Error(object + ' Response has errors');
        }
        const allSubmitted = this.itemsAndConfig.every(
            (item) => item.formState === 'success'
        );
        if (allSubmitted) {
            this.dialogController.ok({
                type: 'multi-product',
                state: 'all-submitted',
            });
        }
    }

    deactivate() {
        UnsavedChangesPrevent.cleanChanges();
    }

    async canDeactivate() {
        return this.unsavedChangesChecker.canDeactivate(
            this.originalUnsavedChanges
        );
    }
}
