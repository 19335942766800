import { InputGroup, Input, Checkbox, SelectPicker } from "rsuite";
import { ChoiceElement } from "../../../../choice/react-choice-element";
import { Foldable } from "../offer-foldable";
import {RADIUS_PICKER_VALUES} from "../../constants";
import { GoogleOfferCityAutoComplete } from "../offer-city-autocomplete";
import { withGMapOptions } from "../../helper/gmap-context";
import SioIcon from "../../../../icon/rsuite-icon-font/SioIcon";

const CityAutoComplete = withGMapOptions(GoogleOfferCityAutoComplete);

export const FerryTabContent = ({
  searchFreeText,
  setSearchFreeText,
  setFilter,
  isDefaultOccupancy,
  setIsDefaultOccupancy,
  isTwinPosition,
  setIsTwinPosition,
  filters,
  sortOptions,
  isNoPrice,
  setNoPrice,
  setFilters
}) => {
    const { startPlace, endPlace } = filters;
    const toggleFilter = () => {
      setFilters((filters) => ({
        ...filters,
        startPlace: endPlace ? endPlace : null,
        endPlace: startPlace ? startPlace : null,
      }));
    };

    return (
  <>
    <div className="col-xs-12 col-lg-4 col-small-padding">
    <div className="form-group">
          <label
            style={{
              display: 'flex',
              gap: '4px',
              width: '100',
              justifyContent: 'space-between',
            }}
          >
            <div>Starthafen</div>
            {(startPlace || endPlace) && (
              <div
                onClick={() => {
                  toggleFilter();
                }}
                style={{ cursor: 'pointer' }}
              >
                <i className="fas fa-chevron-left" />
                <span>{'    '}</span>
                <i className="fas fa-chevron-right" />
              </div>
            )}
          </label>
          <CityAutoComplete
            onSelect={(value) => {
              setFilter('startPlace', value ? value : null);
            }}
            value={filters.startPlace ?? ''}
          />
        </div>

    </div>
    <div className="col-xs-12 col-lg-4 col-small-padding">
      <div className="form-group">
        <label>Zielhafen</label>
        <CityAutoComplete
          onSelect={(value) => {
            setFilter("endPlace", value ? value : null);
          }}
          value={filters.endPlace ?? ""}
        />
      </div>
    </div>
    <div className="col-xs-12 col-lg-4 col-small-padding">
      <div className="form-group radius-picker">
        <label>Radius</label>
        <SelectPicker
          cleanable={false}
          data={RADIUS_PICKER_VALUES}
          value={filters.radius}
          onChange={(value) => {
            setFilter("radius", value ? value : null);
          }}
          className="select-picker-input"
        />
      </div>
    </div>
    <div className="form-group col-xs-12 col-lg-8 col-small-padding">
      <InputGroup inside className="offer-search-parameters_input">
        <Input
          value={searchFreeText}
          onChange={setSearchFreeText}
          placeholder={"Suchbegriff"}
        />
        <InputGroup.Button>
          <SioIcon icon="fa fa-search"/>
        </InputGroup.Button>
      </InputGroup>
    </div>
    <div className="form-group col-xs-12 col-lg-4 col-small-padding">
      <SelectPicker
        data={sortOptions}
        cleanable={false}
        value={filters.sort}
        searchable={sortOptions.length > 10}
        onChange={(value) => {
          setFilter("sort", value ? value : null);
        }}
        className="select-picker-input"
      />
    </div>
    <Foldable>
      <div className="col-xs-12">
        <Checkbox
          checked={isDefaultOccupancy}
          onChange={(value, checked) => setIsDefaultOccupancy(checked)}
        >
          Relevant für Standard Kalkulation
        </Checkbox>
        <Checkbox
          checked={isTwinPosition}
          onChange={(value, checked) => setIsTwinPosition(checked)}
        >
          Zwillingsposition
        </Checkbox>
        <Checkbox
          checked={isNoPrice}
          onChange={(value, checked) => setNoPrice(checked)}
        >
          Ergebnisse ohne Preis anzeigen
        </Checkbox>
      </div>
      <div className="col-xs-6">
        <ChoiceElement
          config={{
            modelId: "tourism-room/occupancy-type",
          }}
          placeholder="Belegungstyp"
          onChange={(value) => {
            setFilter("srgCategory", value ? { $eq: value } : null);
          }}
        />
      </div>
      <div className="col-xs-6">
        <ChoiceElement
          config={{
            modelId: "tourism-room/fare",
          }}
          placeholder="Verpflegung"
          onChange={(value) => {
            setFilter("fare", value ? {$eq: value} : null);
          }}
        />
      </div>
    </Foldable>
  </>
)};
