import React, { useEffect } from "react";
import { Checkbox } from "rsuite";
import { formatPriceWithCommas } from "../helper/utility";
import "./offer-calendar-dialog.less";

export const OfferCalendarDialog = ({
    open,
    handleSuccess,
    handleClose,
    selectedEvent,
    checkboxChangeHandler,
}) => {
    const { name, optional, price } = selectedEvent;
    console.log(selectedEvent, "selectedEvent");
    const formattedPrice = `${formatPriceWithCommas(
        price?.amount,
        price?.currency
    )}`;

    useEffect(() => {
        if (open) {
            $("#myModal").modal({ backdrop: "static", keyboard: false });
        }
    }, [open]);

    return (
        <div
            className="modal fade"
            id="myModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="myModalLabel"
        >
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title" id="myModalLabel">
                            {name}
                        </h4>
                    </div>
                    <div className="modal-body">
                        <div className="calendar-modal--price">
                            Preis: {formattedPrice}
                        </div>
                        <Checkbox
                            checked={optional}
                            onChange={checkboxChangeHandler}
                        >
                            Optionale Leistung
                        </Checkbox>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-default btn-sm"
                            data-dismiss="modal"
                            onClick={handleClose}
                        >
                            Abbrechen
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary btn-sm"
                            data-dismiss="modal"
                            onClick={handleSuccess}
                        >
                            Speichern
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
