import React from "react";
import { Form, InputGroup, InputNumber, Checkbox } from "rsuite";

export const OfferPreviewDuration = ({
    accommodationDuration,
    setAccommodationDuration,
}) => {
    const handleMinus = () => {
        setAccommodationDuration(accommodationDuration - 1);
    };
    const handlePlus = () => {
        setAccommodationDuration(accommodationDuration + 1);
    };

    return (
        <>
            <Form.HelpText className="help-block">Dauer</Form.HelpText>
            <InputGroup className="preview-view-duration">
                <InputGroup.Button onClick={handleMinus}>-</InputGroup.Button>
                <InputNumber
                    className="custom-input-number"
                    value={accommodationDuration}
                    onChange={(value) => {
                        setAccommodationDuration(Number(value));
                    }}
                    max={999999}
                    min={1}
                />
                <InputGroup.Button onClick={handlePlus}>+</InputGroup.Button>
            </InputGroup>
        </>
    );
};
