import React from "react";
import {Col, Loader, Row} from "rsuite";
import {OfferResultsCard} from "./offer-results-card";
import "./offer-search-form.less";
import {ErrorDisplay} from "./error-display";

export const OfferSearchResults = ({
                                       isLoading,
                                       selectedSearchItem,
                                       searchResults = [],
                                       errors,
                                       onSelectResult,
                                       showPurchase,
                                   }) => {
    if (isLoading) return <Loader/>;

    if (selectedSearchItem) {
        return (
            <Row gutter={0} className="offer-search-tabs__row">
                <OfferResultsCard item={selectedSearchItem} showPurchase={showPurchase}/>
            </Row>
        );
    }

    return <>
        {searchResults.length > 0 &&
            <Row gutter={0} className="offer-search-tabs__row">
                {searchResults.map((item, index) => (
                    <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        key={index}
                        onClick={() => onSelectResult(item)}
                    >
                        <OfferResultsCard item={item} showPurchase={showPurchase}/>
                    </Col>
                ))}
            </Row>
        }
        {!isLoading && searchResults.length === 0 && <p className="text-center">Keine Einträge gefunden</p>}

        <ErrorDisplay errors={errors}></ErrorDisplay>
    </>;
};
