import React, {useState} from "react";
import {Row, Col, Loader} from "rsuite";
import {OfferResultsCard} from "./offer-results-card";
import "./offer-search-form.less";

export const ErrorDisplay = (
    {
        errors,
    }) => {

    const [unfoldErrors, setUnfoldErrors] = useState(false);

    return <>

        {errors && errors.length > 0 && <>
            <Row gutter={0} className="offer-search-tabs__row">

                <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    onClick={() => setUnfoldErrors(!unfoldErrors)}
                >
                    <div className="alert alert-warning small" role="alert"
                         style={{wordBreak: "break-word", marginTop: "20px"}}>
                        <span style={{cursor: 'pointer'}}><i
                            className={unfoldErrors ? "fa fa-angle-down" : "fa fa-angle-up"}></i> {errors.length} Warnungen bei Suche</span><br/>

                        {unfoldErrors && errors.map((errorMessages, index) => {
                            return <>
                                <b>
                                    <a
                                        href={`/view/${errorMessages.modelId}?id=${errorMessages.id}`}
                                        target={'_blank'}>{errorMessages.title}
                                    </a>:
                                </b><br/>
                                <ul>
                                    {errorMessages.messages.map(message => {
                                        return <li>{message}</li>;
                                    })}
                                </ul>
                            </>;
                        })}
                    </div>
                </Col>
            </Row>
        </>}
    </>;
};
