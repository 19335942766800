import { IconButton, Icon, Tooltip, Whisper } from "rsuite";
import { ReferenceDisplay } from "../../../reference/react-reference-display";
import { EVENT_VIEW } from "../constants/index";
import { TimeValueConverter } from "../../../converters";
import React from "react";
import SioIcon from "../../../icon/rsuite-icon-font/SioIcon";

const TravelEvent = ({ event }) => {
  const valueConverter = new TimeValueConverter();

  const {
    extendedProps: {
      distance,
      duration,
      drivingTime,
      bufferTime,
      breakTime,
      serviceTime,
    },
  } = event;

  return (
    <>
      <div className="travel-vr" />
      <div className="event-text">
        <Whisper
          trigger="hover"
          speaker={
            <Tooltip>
              <span>
                Fahrzeit: {valueConverter.toView(drivingTime, "00:00")}
                <br />
                {serviceTime !== 0.0 ? (
                  <>
                    Puffer vor/nach:{" "}
                    {valueConverter.toView(serviceTime, "00:00")}
                    <br />
                  </>
                ) : null}
                {breakTime !== 0.0 ? (
                  <>
                    Pause: {valueConverter.toView(breakTime, "00:00")}
                    <br />
                  </>
                ) : null}
                {bufferTime < 0 ? (
                  <span>
                    {valueConverter.toView(bufferTime * -1, "00:00")} zu spät
                  </span>
                ) : null}
                {bufferTime > 0 ? (
                  <span>
                    {valueConverter.toView(bufferTime, "00:00")} zu früh
                  </span>
                ) : null}
              </span>
            </Tooltip>
          }
        >
          <span>
            <span>{Math.round(distance / 1000)} km</span> /{" "}
            <span>{valueConverter.toView(duration, "00:00")}</span>
            {bufferTime < 0 ? (
              <span> (+{valueConverter.toView(bufferTime * -1, "00:00")})</span>
            ) : null}
          </span>
        </Whisper>
      </div>
    </>
  );
};

const AccommodationSurrogateEvent = ({ event }) => {
  return (
    <Whisper
      trigger="hover"
      speaker={<Tooltip>{event.title}</Tooltip>}
      delay={500}
    >
      <div className="event-content">
        <span className="event-text">
          <i className="accommodation-surrogate-event">{event.title}</i>
        </span>
      </div>
    </Whisper>
  );
};
const AccommodationEvent = ({ timeText, event, children }) => {
  const { city, fare, roomCategory, country, cloneError } = event.extendedProps;
  return (
    <>
      <div className="event-text">
        <b>{timeText}</b>
        <div>
          {event.title}{" "}
          {cloneError ? (
            <i className="text-white fa fa-triangle-exclamation"></i>
          ) : null}
        </div>
        <div>
          <i>
            {city} {country}
          </i>
        </div>
        <div>
          <ReferenceDisplay reference={roomCategory} />{" "}
          <ReferenceDisplay reference={fare} />
        </div>
      </div>
      {children}
    </>
  );
};

const ServiceEvent = ({ timeText, event, children }) => {
  const {cloneError, tariff} = event.extendedProps;

  return (
    <>
      <div className="event-text">
        <b>{timeText}</b>
        <div>
          {event.title}{" "}
          {cloneError ? (
            <i className="text-white fa fa-triangle-exclamation"></i>
          ) : null}
        </div>
        {tariff && <div>
          <ReferenceDisplay reference={tariff}/>
        </div>}
      </div>
      {children}
    </>
  );
};

const CalendarEvent = React.memo(({ event, timeText, onDeleteEvent }) => {
  const { eventView, isAccommodation } = event.extendedProps;

  switch (eventView) {
    case EVENT_VIEW.TRAVEL: {
      return <TravelEvent event={event} />;
    }
    case EVENT_VIEW.ACCOMMODATION_SURROGATE:
      return <AccommodationSurrogateEvent event={event} />;
    case EVENT_VIEW.ACCOMMODATION:
    case EVENT_VIEW.SERVICE: {
      const Event = isAccommodation ? AccommodationEvent : ServiceEvent;
      return (
        <Whisper
          trigger="hover"
          speaker={
            <Tooltip>
              {event.title}
              {event.extendedProps?.tariff && (
                <ReferenceDisplay reference={event.extendedProps.tariff} />
              )}
              {event.extendedProps?.roomCategory && (
                <><ReferenceDisplay reference={event.extendedProps.roomCategory} />{" "}</>
              )}
              {event.extendedProps?.fare && (
                <ReferenceDisplay reference={event.extendedProps.fare} />
              )}
            </Tooltip>
          }
          delay={500}
        >
          <div className="event-content">
            <Event timeText={timeText} event={event}>
              {event.extendedProps.editable ? <IconButton
                onClick={() => onDeleteEvent?.(event)}
                appearance="subtle"
                size="xs"
                placement="right"
                icon={<SioIcon icon="close" />}
                className="delete-button"
              /> : null}
            </Event>
          </div>
        </Whisper>
      );
    }
    default: {
      return <></>;
    }
  }
});

export default CalendarEvent;
