import React, {useEffect, useState} from "react";
import {Container} from "aurelia-framework";
import {Client} from "../../api/client";
import {CurrencyValueConverter} from "../../currency/currency-value-converter";
import {sum} from "../../money/money-tools";
import {clone, debounce} from 'lodash';
import {useDebounce} from "../../utilities/debounce";
import {FlashService} from "../../flash/flash-service";
import {Button, Loader, Modal, Popover, Whisper} from "rsuite";
import ValueFormatter from "../../statistics/service/value-formatter";
import update from "immutability-helper";
import {EventAggregator} from "aurelia-event-aggregator";
import {Chart} from "../../statistics/time-aggregation/chart";
import moment from "moment-timezone";

import "./calculation.less";
import numeral from "numeral";
import {UnsavedChangesPrevent} from "../../form/unsaved-changes-prevent";

const LoadingValue = ({value, loading, netValue, taxValue, taxPercentages, reverseCharge, purchaseAndRC, purchaseRaw, securityMargin, currencyRate}) => {

    const speaker = netValue ? <Popover>
        <table className="table table-super-condensed calculation-overlay">
            <tbody>
            {purchaseRaw != null ?
                <tr>
                    <th>EK (roh):</th>
                    <td>{purchaseRaw}</td>
                    <th>Sicherheit:</th>
                    <td>{securityMargin}</td>
                    <th>WK:</th>
                    <td>{currencyRate}</td>
                </tr> : null }
            <tr>
                <th>Netto:</th>
                <td>{netValue}</td>
                <th>Brutto:</th>
                <td>{value}</td>
                <th>Brutto + RC:</th>
                <td>{purchaseAndRC}</td>
            </tr>
            <tr>
                {reverseCharge != null && reverseCharge != '-' ?<>
                    <th>RC:</th>
                    <td>{reverseCharge} {taxPercentages ? '(' + taxPercentages + ')' : ''}</td><td colSpan={4}></td></> :
                    <>
                        <th>Steuer:</th>
                        <td>{taxValue} {taxPercentages ? '(' + taxPercentages + ')' : ''}</td>
                        <td colSpan={4}></td>
                    </>
                }
            </tr>
            </tbody>
        </table>
    </Popover> : <div/>;

    return <>
        {loading ? <Loader/> : <Whisper trigger="hover" placement="auto" speaker={speaker}>
            <span>{value}</span>
        </Whisper>}
    </>
}

export const Calculation = (props) => {

    const CHANGE_KEY = 'order-calculation';

    const {order} = props;

    const client = Container.instance.get(Client);
    const currencyValueConverter = Container.instance.get(CurrencyValueConverter);
    const flashService = Container.instance.get(FlashService);
    const ea = Container.instance.get(EventAggregator);

    console.log('ABC', order.calculationEntries);

    const defaultColumns = order.calculationEntries ?? [];
    let defaults = [20, 25, 30];
    let leftOptionalFromDateFound = false;
    let rightOptionalFromDateFound = false;

    if (order.b2bParticipants && defaults.indexOf(order.b2bParticipants) == -1) {
        defaults.push(order.b2bParticipants);
    }

    for (const defaultValue of defaults) {
        let found = false;

        for (let defaultColumn of defaultColumns) {
            if (defaultColumn.column === defaultValue) {
                found = true;
                break;
            }
        }

        if (!found) {
            defaultColumns.push({column: defaultValue});
        }
    }

    defaultColumns.sort((a, b) => {
        return a.column - b.column;
    });

    const [activeColumn, setActiveColumn] = useState(order.b2bParticipants ?? defaultColumns[0].column);
    const [columns, setColumns] = useState(defaultColumns);
    const [date, setDate] = useState(moment(order.offerStartDate).format("YYYY-MM-DD"));

    const [optionalStartDates, setOptionalStartDates] = useState(order.optionalStartDates);

    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [modal, setModalOpen] = useState(false);

    const [modalOptionalFromFrom, setModalOptionalFromForm] = useState(false);

    const [data, setData] = useState(null);
    const [graphData, setGraphData] = useState(null);
    const [updateTrigger, setUpdateTrigger] = useState(0);

    const [newColumn, setNewColumn] = useState(null);

    const debouncedDate = useDebounce(date);

    const fetchItems = async (columns) => {

        setLoading(true);

        try {
            let data = (await client.post(
                "order-calculation/query/" + order.id,
                {
                    columns: columns,
                    date: date
                }
            )).data;

            setData(data);
            setColumns(data.columns.filter(
                column => (moment(date).diff(moment(column.date).startOf('day'), 'days') === 0)
            ));

            //add original order fromDate to have a default value
            data?.optionalStartDates.unshift({
                'fromDate': order.fromDate,
                'modelId': "order/optional-start-date"
            })

            setOptionalStartDates(data?.optionalStartDates);

        } catch (e) {

            flashService.error(e.data.message ?? 'Kalkulation konnte nicht geladen werden');

        } finally {
            setLoading(false);
        }
    };

    const openModalOptionalFromDateWindow = () => {
        setModalOptionalFromForm(true);
    };

    const addOptionalFromDate = () => {
        setLoading(true);
        UnsavedChangesPrevent.registerUnsavedChanges(CHANGE_KEY);

        client.post((
            "order-calculation/add-optional-from-date/" + order.id + "/" + date
        )).then((result) => {

            //add original order fromDate to have a default value
            result.data.unshift({
                'fromDate': order.fromDate,
                'modelId': "order/optional-start-date"
            })

            setOptionalStartDates(result.data);
            setLoading(false);
            setModalOptionalFromForm(false);

        }).catch(e => {
            flashService.error(e.data.message ?? 'Kalkulation konnte nicht geladen werden');
            setLoading(false);
        });
    };

    const fetchGraph = () => {

        setGraphData(null);
        setLoading(true);

        (client.post(
            "order-calculation/graph/" + order.id,
            {
                columns: columns,
                date: date
            }
        )).then((result) => {

            setGraphData(result.data);
            setModalOpen(true);
            setLoading(false);

        }).catch(e => {
            flashService.error(e.data.message ?? 'Graph konnte nicht geladen werden');
            setLoading(false);
        });
    };

    useEffect(() => {
        fetchItems(columns);

    }, [order.id, debouncedDate, updateTrigger]);

    const getGroupedRows = (rows) => {
        let groupedRows = {};

        for (let row of rows) {

            if (!groupedRows[row.group]) {
                groupedRows[row.group] = [];
            }

            groupedRows[row.group].push(row);
        }

        return groupedRows;
    }

    const patchOrder = async () => {
        setSaving(true);

        const url = `order-calculation/update-margins/${order.id}`;

        const payload = {
            columns: columns,
            activeColumn: activeColumn,
            data: data,
            date: date
        };

        try {
            await client.patch(url, payload);

            ea.publish('sio_form_post_submit', {config: {modelId: 'order/order'}});

            flashService.success('Erfolgreich gespeichert.');
            UnsavedChangesPrevent.unregisterUnsavedChanges(CHANGE_KEY);
        } catch (e) {
            flashService.error(e.data.message ?? 'Kalkulation konnte nicht geladen werden');
        } finally {
            setSaving(false);
        }
    };

    const sumForRows = (rows, index, key) => {

        const moneys = [];

        for (let row of rows) {

            if (row.optional || row.singleMode === 'onlySingle') {
                continue;
            }

            if (row?.[key]?.[index]) {
                moneys.push(row[key][index]);
            } else {
                return '-'
            }
        }

        return currencyValueConverter.toView(sum(moneys));
    }

    const addColumn = () => {
        if (null == newColumn || '' == newColumn) {
            return;
        }

        let col = clone(columns);

        col.push({column: newColumn, date: date});

        col.sort((a, b) => {
            return a.column - b.column;
        });

        setColumns(col);
        setNewColumn('');
        debouncedUpdateTrigger();
        UnsavedChangesPrevent.registerUnsavedChanges(CHANGE_KEY);
    }

    const removeColumn = (index) => {
        setColumns(update(columns, {$splice: [[index, 1]]}));
        debouncedUpdateTrigger();
    };

    const setShowInOffer = (index, showInOffer) => {
        let cols = clone(columns);
        cols[index].showInOffer = showInOffer;
        setColumns(cols);
    }

    const debouncedUpdateTrigger = debounce(() => {
        setUpdateTrigger(updateTrigger + 1);
    }, 1500);

    const updateMargin = (index, inland, newValue) => {

        let cols = clone(columns);
        cols[index].total = null;
        cols[index].totalSingle = null;

        if (inland) {
            cols[index].inland = newValue;
            cols[index].inlandSingle = newValue;
        } else {
            cols[index].other = newValue;
            cols[index].otherSingle = newValue;
        }

        setColumns(cols);
        debouncedUpdateTrigger();
        UnsavedChangesPrevent.registerUnsavedChanges(CHANGE_KEY);
    };

    const updatePrice = (index, newValue) => {

        let cols = clone(columns);
        cols[index].total = newValue;
        cols[index].inland = null;
        cols[index].other = null;

        setColumns(cols);
        debouncedUpdateTrigger();
        UnsavedChangesPrevent.registerUnsavedChanges(CHANGE_KEY);
    };

    const updateSinglePrice = (index, newValue) => {

        let cols = clone(columns);
        cols[index].totalSingle = newValue;
        cols[index].inlandSingle = null;
        cols[index].otherSingle = null;

        setColumns(cols);
        debouncedUpdateTrigger();
        UnsavedChangesPrevent.registerUnsavedChanges(CHANGE_KEY);
    };

    console.log('data', data);

    const groupedRows = getGroupedRows(data?.rows ?? []);

    console.log('data', groupedRows);

    return (
        <>
            <Modal
                size="large"
                open={modal}
                onClose={() => {
                    setModalOpen(false);
                }
                }>
                <Modal.Header>
                    <Modal.Title>Graph</Modal.Title>
                </Modal.Header>

                <Modal.Body>

                    <Chart config={{
                        padding: {
                            right: 30
                        },
                        data: {
                            x: 'x',
                            columns: graphData,
                        },
                        axis: {
                            x: {
                                type: 'timeseries',
                                tick: {format: "%d.%m.%Y"}
                            },
                            y: {
                                tick: {
                                    format: value => ValueFormatter.format('money', value)
                                }
                            }
                        },
                        title: {
                            text: ''
                        },
                        tooltip: {
                            tick: {
                                format: value => ValueFormatter.format('money', value)
                            }
                        }
                    }}/>
                </Modal.Body>
            </Modal>
            <Modal
                open={modalOptionalFromFrom}
                onClose={() => {
                    setModalOptionalFromForm(false);
                }
                }>
                <Modal.Header>
                    <Modal.Title>Alternatives Starttermin hinzufügen</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div>
                        <input className="form-control"
                               type="date"
                               value={date}
                               onChange={(event) => {
                                   setDate(event.target.value);
                               }}
                        />
                    </div>
                    <button
                        onClick={() => {
                            addOptionalFromDate()
                        }}
                        className="btn btn-primary pull-right" style={{marginTop: '10px'}}
                        type="button"
                        disabled={loading}
                    >
                        {loading ? (
                            <span className="fa fa-spinner fa-pulse fa-fw"/>
                        ) : null}
                        Speichern
                    </button>
                </Modal.Body>
            </Modal>
            <div className="panel order-calculation">
                <div className="panel-body">
                    <table className="table table-condensed table-bordered table-hover">

                        <thead>
                        <tr>
                            <th>
                                Abweichender Starttermin
                            </th>
                            <th colSpan={columns.length} className={"order-calculation-actions-header"}>
                                {optionalStartDates.sort((a, b) => {
                                    return new Date(b.fromDate).getTime() - new Date(a.fromDate).getTime();
                                }).map(optionalStartDate => {
                                    if (moment(date).diff(moment(optionalStartDate.fromDate).startOf('day'),'days') <= 0 || leftOptionalFromDateFound) {
                                        return null;
                                    }

                                    leftOptionalFromDateFound = true;

                                    return <div className={"d-inline-block"}>
                                        <Button onClick={(event) => {
                                            setDate(moment(optionalStartDate.fromDate).format("YYYY-MM-DD"));
                                        }}>{moment(optionalStartDate.fromDate).format( "DD.MM.YYYY")} {"<"} </Button>
                                    </div>;
                                })}

                                <div className={"d-inline-block"}>
                                    <Button onClick={(event) => {
                                        setDate(moment(date).add(-1, 'days').format("YYYY-MM-DD"));
                                    }}>- 1 Tag</Button>
                                </div>

                                <div className={"d-inline-block"}>
                                    <input className="form-control"
                                           type="date"
                                           value={date}
                                           onChange={(event) => {
                                               setDate(event.target.value);
                                           }}
                                    />
                                </div>

                                <div className={"d-inline-block"}>
                                    <Button onClick={(event) => {
                                        setDate(moment(date).add(1, 'days').format("YYYY-MM-DD"));
                                    }}>+ 1 Tag</Button>
                                </div>

                                {optionalStartDates.sort((a, b) => {
                                    return new Date(a.fromDate).getTime() - new Date(b.fromDate).getTime();
                                }).map(optionalStartDate => {
                                    if (moment(optionalStartDate.fromDate).startOf('day').diff(moment(date), 'days') <= 0 || rightOptionalFromDateFound) {
                                        return null;
                                    }

                                    rightOptionalFromDateFound = true;

                                    return <div className={"d-inline-block"}>
                                        <Button onClick={(event) => {
                                            setDate(moment(optionalStartDate.fromDate).format("YYYY-MM-DD"));
                                        }}>{">"} {moment(optionalStartDate.fromDate).format("DD.MM.YYYY")}</Button>
                                    </div>;
                                })}

                                <div className={"d-inline-block"}>
                                    <button onClick={openModalOptionalFromDateWindow} className="btn btn-success btn-sm" type="button">
                                        +
                                    </button>
                                </div>
                            </th>

                            <th>
                                <Button onClick={fetchGraph}>Graph</Button>
                            </th>
                        </tr>
                        <tr>

                            <th>
                                Leistung
                            </th>
                            {columns.map((column, index) => <th>
                                {column.column}
                                    <button onClick={() => {
                                        removeColumn(index);
                                    }} className="btn btn-sm btn-danger pull-right" type="button">
                                        -
                                    </button>
                            </th>)}
                            <th style={{width: '150px'}}>

                                <div className="input-group">
                                    <input type="text" className="form-control" value={newColumn} onChange={(event) => {
                                        setNewColumn(event.target.value)
                                    }}/>
                                    <span className="input-group-btn">
                                        <button onClick={() => {
                                            addColumn()
                                        }} className="btn btn-success btn-sm" type="button">
                                            +
                                        </button>
                                    </span>
                                </div>
                            </th>
                        </tr>
                        </thead>
                        {Object.keys(groupedRows).map(group => {

                            const rows = groupedRows[group];

                            return <tbody>
                            <tr className="active">
                                <th>{group}</th>
                                {columns.map((column, index) => {
                                    return <th><LoadingValue value={sumForRows(rows, index, 'purchase')}
                                                             netValue={sumForRows(rows, index, 'net')}
                                                             taxValue={sumForRows(rows, index, 'tax')}
                                                             loading={loading}></LoadingValue></th>
                                })}
                                <th/>
                            </tr>
                            {rows.map(row => {

                                if (row.optional || row.singleMode === 'onlySingle') {
                                    return null;
                                }

                                return <tr>
                                    <td>
                                        {row.noPrice ?
                                            <span style={{fontWeight: 'bold'}} className={"text-danger"}>{row.title} <i
                                                className="text-danger fa fa-triangle-exclamation"></i></span> : row.title}
                                    </td>
                                    {columns.map((column, index) => {
                                        return <td>
                                            <LoadingValue
                                                value={row?.purchase?.[index] ? currencyValueConverter.toView(row.purchase[index]) : '-'}
                                                netValue={row?.net?.[index] ? currencyValueConverter.toView(row.net[index]) : '-'}
                                                taxValue={row?.tax?.[index] ? currencyValueConverter.toView(row.tax[index]) : '-'}
                                                taxPercentages={row?.taxPercentages?.[index]}
                                                reverseCharge={row?.reverseCharges?.[index] ? currencyValueConverter.toView(row.reverseCharges[index]) : '-'}
                                                purchaseAndRC={row?.purchaseAndRCs?.[index] ? currencyValueConverter.toView(row.purchaseAndRCs[index]) : '-'}
                                                purchaseRaw={row?.purchaseCurrency?.[index] ? currencyValueConverter.toView(row.purchaseCurrency[index]) : '-'}
                                                securityMargin={row?.securityMargins?.[index] ? currencyValueConverter.toView(row.securityMargins[index]) : '-'}
                                                currencyRate={row?.currencyRates?.[index] ? (Math.round(row.currencyRates[index] * 100) / 100) : '-'}
                                                loading={loading}></LoadingValue>
                                        </td>
                                    })}
                                    <td/>
                                </tr>;
                            })}
                            </tbody>

                        })}

                        <tfoot>
                        <tr className="active">
                            <th>Gesamt EK</th>
                            {columns.map((column, index) => {
                                return <th>
                                    <LoadingValue value={sumForRows(data?.rows ?? [], index, 'purchase')}
                                                  netValue={sumForRows(data?.rows ?? [], index, 'net')}
                                                  taxValue={sumForRows(data?.rows ?? [], index, 'tax')}
                                                  loading={loading}></LoadingValue>
                                </th>
                            })}
                            <th/>
                        </tr>
                        <tr className="active">
                            <th>Inland</th>
                            {columns.map((column, index) => {
                                return <th/>;
                            })}
                            <th/>
                        </tr>
                        <tr>
                            <td>Aufschlag % DZ</td>
                            {columns.map((column, index) => {
                                return <td>
                                    <input type="number"
                                           className={ column.inland !== 0 && column.inland < data?.minimumUpcharge ? 'form-control is-invalid' : 'form-control' }
                                           value={(column.inland ? (Math.round(column.inland * 10000) / 100) : 0)}
                                           onChange={(event) => {
                                               updateMargin(index, true, event.target.value / 100);
                                           }}/>
                                </td>
                            })}
                            <td/>
                        </tr>
                        <tr>
                            <td>Aufschlag p.P € DZ</td>
                            {columns.map((column, index) => {
                                return <td>
                                    <LoadingValue
                                        value={column.inlandCharge ? currencyValueConverter.toView(column.inlandCharge) : '-'}
                                        loading={loading}></LoadingValue>
                                </td>
                            })}
                            <td/>
                        </tr>
                        <tr>
                            <td>Aufschlag % EZ</td>
                            {columns.map((column, index) => {
                                return <td className={ column.inlandSingle !== 0 && column.inlandSingle < data?.minimumUpcharge ? 'text-danger font-weight-bold' : '' }>
                                    { numeral(column.inlandSingle).format('0.00%') }
                                </td>
                            })}
                            <td/>
                        </tr>
                        <tr>
                            <td>Aufschlag p.P € EZ</td>
                            {columns.map((column, index) => {
                                return <td>
                                    <LoadingValue
                                        value={column.inlandChargeSingle ? currencyValueConverter.toView(column.inlandChargeSingle) : '-'}
                                        loading={loading}></LoadingValue>
                                </td>
                            })}
                            <td/>
                        </tr>
                        <tr className="active">
                            <th>Drittland</th>
                            {columns.map((column, index) => {
                                return <th/>;
                            })}
                            <th/>
                        </tr>
                        <tr>
                            <td>Aufschlag % DZ</td>
                            {columns.map((column, index) => {
                                return <td>
                                    <input type="number"
                                           className={ column.other !== 0 && column.other < data?.minimumUpcharge ? 'form-control is-invalid' : 'form-control' }
                                           value={(column.other ? (Math.round(column.other * 10000) / 100) : 0)}
                                           onChange={(event) => {
                                               updateMargin(index, false, event.target.value / 100);
                                           }}/>
                                </td>
                            })}
                            <td/>
                        </tr>
                        <tr>
                            <td>Aufschlag p.P € DZ</td>
                            {columns.map((column, index) => {
                                return <td>
                                    <LoadingValue
                                        value={column?.otherCharge ? currencyValueConverter.toView(column?.otherCharge) : '-'}
                                        loading={loading}></LoadingValue>
                                </td>
                            })}
                            <td/>
                        </tr>
                        <tr>
                            <td>Aufschlag % EZ</td>
                            {columns.map((column, index) => {
                                return <td className={ column.otherSingle !== 0 && column.otherSingle < data?.minimumUpcharge ? 'text-danger font-weight-bold' : '' }>
                                    { numeral(column.otherSingle).format('0.00%') }
                                </td>
                            })}
                            <td/>
                        </tr>
                        <tr>
                            <td>Aufschlag p.P € EZ</td>
                            {columns.map((column, index) => {
                                return <td>
                                    <LoadingValue
                                        value={column?.otherChargeSingle ? currencyValueConverter.toView(column?.otherChargeSingle) : '-'}
                                        loading={loading}></LoadingValue>
                                </td>
                            })}
                            <td/>
                        </tr>
                        <tr className="active">
                            <th>Arrangement Preis im DZ</th>
                            {columns.map((column, index) => {
                                return <th>
                                    <input type="number"
                                           className="form-control"
                                           value={(column.total ? column.total / 100 : '')}
                                           onChange={(event) => {
                                               updatePrice(index, event.target.value * 100);
                                           }}/>
                                </th>
                            })}
                            <th/>
                        </tr>
                        <tr className="active">
                            <th>Arrangement Preis im DZ + RC Anteil</th>
                            {columns.map((column, index) => {
                                return <th>
                                    <LoadingValue
                                        value={column?.rcTotal ? currencyValueConverter.toView({
                                            amount: column.rcTotal,
                                            currency: 'EUR'
                                        }) : '-'}
                                        loading={loading}></LoadingValue>
                                </th>
                            })}
                            <th/>
                        </tr>
                        <tr className="active">
                            <th>Arrangement Preis im EZ</th>
                            {columns.map((column, index) => {
                                return <th>
                                    <input type="number"
                                           className="form-control"
                                           value={(column.totalSingle ? column.totalSingle / 100 : '')}
                                           onChange={(event) => {
                                               updateSinglePrice(index, event.target.value * 100);
                                           }}/>
                                </th>
                            })}
                            <th/>
                        </tr>
                        <tr className="active">
                            <th>Arrangement Preis im EZ + RC Anteil</th>
                            {columns.map((column, index) => {
                                return <th>
                                    <LoadingValue
                                        value={column?.rcTotalSingle ? currencyValueConverter.toView({
                                            amount: column.rcTotalSingle,
                                            currency: 'EUR'
                                        }) : '-'}
                                        loading={loading}></LoadingValue>
                                </th>
                            })}
                            <th/>
                        </tr>
                        <tr>
                            <th>Kalkulationsbasis</th>
                            {columns.map((column, index) => {
                                return <td>
                                    <label>
                                        <input type="radio"
                                               checked={activeColumn == column.column}
                                               onChange={() => {
                                                   setActiveColumn(column.column)
                                               }}
                                        />
                                    </label>
                                </td>
                            })}
                            <td></td>
                        </tr>
                        <tr>
                            <th>Im Angebot anzeigen</th>
                            {columns.map((column, index) => {
                                return <td>
                                    <label>
                                        <input type="checkbox"
                                               checked={column.showInOffer}
                                               onChange={(event) => {
                                                   setShowInOffer(index, event.target.checked);
                                               }}
                                        />
                                    </label>
                                </td>
                            })}
                            <td></td>
                        </tr>
                        </tfoot>

                    </table>

                    <button onClick={() => {
                        patchOrder()
                    }} className="btn btn-primary pull-right" style={{marginTop: '10px'}} type="button">
                        { saving ? <Loader></Loader> : 'Speichern'}
                    </button>
                </div>
            </div>
        </>
    );
};

export default Calculation;
