import {DialogController} from "aurelia-dialog";
import {inject} from "aurelia-framework";
import {Client} from '../api/client';
import {FlashService} from "../flash/flash-service";

import "./insurance-api-choice.less";

@inject(DialogController, Client, FlashService)
export class InsuranceApiChoice {
    loading;
    constructor(dialogController, client, flash) {
        this.dialogController = dialogController;
        this.client = client;
        this.flash = flash;
    }


    async activate(context) {
        this.data = context.data;
        this.title = context.title;

        this.insuranceCustomerSelection = false;
        this.insuranceManualAmount = false;

        let customerConditions = {
            '$or': []
        }

        if (this.data.participants.length > 0) {
            customerConditions.$or.push({
                id: {$in: this.data.participants},
                'customer.modelId': 'customer/customer',
            });
        }
        this.insuranceCustomerConfig = {
            modelId: 'tourism/journey-participant',
            conditions: customerConditions,
            hideCreateAction: true,
            required: false
        }

        this.insuranceCustomer = null

        try {
            const response = await this.client.post('tourism-insurance-api/backend-offers', {
                participants: this.data.participants
            });

            this.offers = response.data.offers;
            this.request = response.data.request;
            this.warnings = response.data.warnings;
            this.insuranceCustomerSelection = response.data.insuranceSettings?.insuranceCustomerSelection
            this.insuranceManualAmount = response.data.insuranceSettings?.insuranceManualAmount;
            this.selected = null;
            this.additional = [];

            const providers = new Set(response.data.offers.map(offer => offer.provider));

            this.multiple = 1 < providers.size;

            this.insuranceManualAmountValue = this.request.participants[0].tripCost;
        } catch (e) {
            this.message = e.data?.localizedMessage ?? e.data?.message ?? "Unbekannter Fehler";
        }
    }

    select() {
        if (!this.selected) {
            this.dialogController.cancel();
        }

        let data = {
            id: this.selected.id,
            provider: this.selected.provider,
            additional: this.additional.map(({id, provider}) => ({id, provider}))
        }

        if (this?.insuranceCustomer) {
            data.insuranceCustomer = this?.insuranceCustomer ?? null;
        }

        this.data.data = Object.assign({}, this.data.data, data);

        this.dialogController.ok(this.data);
    }

    async reload() {
        this.loading = true;
        try {
            const response = await this.client.post('tourism-insurance-api/backend-offers', {
                participants: this.data.participants,
                manualAmount: this.insuranceManualAmountValue
            });

            this.offers = response.data.offers;
            this.request = response.data.request;
            this.warnings = response.data.warnings;
            this.insuranceCustomerSelection = response.data.insuranceSettings?.insuranceCustomerSelection
            this.insuranceManualAmount = response.data.insuranceSettings?.insuranceManualAmount;
            this.selected = null;
            this.additional = [];

            const providers = new Set(response.data.offers.map(offer => offer.provider));
            this.multiple = 1 < providers.size;

        } catch (e) {
            this.message = e.data?.localizedMessage ?? e.data?.message ?? "Unbekannter Fehler";
        }
        this.loading = false;
    }
}
