import React, {useContext} from "react";
import AdminIcon from "@rsuite/icons/Admin";
import {OrderContext} from "../helper/order-context";
import {sum} from "../../../money/money-tools";
import {formatPriceWithCommas} from "../helper/utility";
import cs from "classnames"

const PriceInfo = ({label, prices, participantsCount}) => {

  return (
    <div>
      <span>{label}</span>
      <span>{prices}</span>
    </div>
  );
};

export const OfferPricing = ({className}) => {
  const {orderItems, order, participantsOrder: totalParticipants} = useContext(
    OrderContext
  );
  const isPriceAvailableToDisplay = orderItems.length > 0;

  let purchaseSum = [];
  let retailSum = [];

  for (let b2bGroup of order.b2bGroups) {

    let purchases = [
      {
        amount: 0,
        currency: order.currency,
      }
    ];
    let retails = [
      {
        amount: 0,
        currency: order.currency,
      }
    ];

    let groupMatchingCode = b2bGroup.tempB2bGroupMatchingCode ?? b2bGroup.group.id;

    for (let orderItem of orderItems) {

      if (orderItem.optional || !orderItem.containedInOffer) {
        continue;
      }

      let matchingCode = orderItem.tempB2bGroupMatchingCode ?? orderItem?.b2bGroup?.id;

      if (matchingCode == null || matchingCode === groupMatchingCode) {
        purchases.push(orderItem.offerCalculatedItemPricePerPerson);
        retails.push(orderItem.offerItemPricePerPerson);
      }
    }

    purchases = sum(purchases);
    purchases = formatPriceWithCommas(
      purchases?.amount,
      purchases?.currency
    );

    retails = sum(retails);
    retails = formatPriceWithCommas(
      retails?.amount,
      retails?.currency
    );

    purchaseSum.push(purchases + ' ' + b2bGroup.title);
    retailSum.push(retails + ' ' + b2bGroup.title);
  }

  return isPriceAvailableToDisplay ? (
    <div className={cs(className)}>
      <PriceInfo label={"EK p.P:"} prices={purchaseSum.join(' / ')}/>
      <PriceInfo
        label={"VK p.P:"}
        prices={retailSum.join(' / ')}
      />
    </div>
  ) : null;
};
