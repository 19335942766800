import PlacesAutocomplete from "react-places-autocomplete";
import React, { useEffect, useState } from "react";
import { GoogleApiWrapper } from "google-maps-react";

const OfferCityAutoComplete = ({ value = "", onSelect }) => {
    const [address, setAddress] = useState(value);

    useEffect(() => {
        setAddress(value || "");
    }, [value]);

    const changeAddress = (v) => {
        if (v == null || v == "") {
            onSelect(null);
        }

        setAddress(v);
    };

    return (
        <PlacesAutocomplete
            value={address}
            onChange={changeAddress}
            onSelect={onSelect}
            searchOptions={{ types: ["(cities)"] }}
            shouldFetchSuggestions={address.length > 2}
        >
            {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
            }) => (
                <div className={"autocomplete-input-container"}>
                    <input
                        {...getInputProps({
                            placeholder: "Stadt",
                            className: "form-control",
                            autoComplete: "nothing",
                        })}
                    />
                    {address.length > 3 && (
                        <div className={"autocomplete-dropdown-container"}>
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                                const className = suggestion.active
                                    ? "suggestion-item--active"
                                    : "suggestion-item";
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                    ? {
                                          backgroundColor: "#fafafa",
                                          cursor: "pointer",
                                      }
                                    : {
                                          backgroundColor: "#ffffff",
                                          cursor: "pointer",
                                      };
                                return (
                                    <div
                                        {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                        })}
                                        key={suggestion.placeId}
                                    >
                                        <span>{suggestion.description}</span>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
            )}
        </PlacesAutocomplete>
    );
};

export const GoogleOfferCityAutoComplete = GoogleApiWrapper((props) => ({
    apiKey: props.apiKey,
    language: props.language,
}))(OfferCityAutoComplete);
