import React from "react";
import { PanelGroup, Panel, List, IconButton } from "rsuite";
import { HolidayCheck } from "../../../tourism/holiday-check-rating/holiday-check";
import { NumberValueConverter } from "../../../converters";
import SioIcon from "../../../icon/rsuite-icon-font/SioIcon";
export const OfferPreviewDetails = ({
    title,
    teasers,
    images,
    description,
    closePreview,
    product,
}) => {
    const rating = product?.holidayCheckRating;
    const number = new NumberValueConverter();

    const holidayCheck = new HolidayCheck(number);
    return (
        <>
            <div className="preview-view-heading">
                <h4 className="preview-view-title">{title}</h4>
                <IconButton
                    className="preview-view__close-button"
                    onClick={() => {
                        closePreview();
                    }}
                    appearance="ghost"
                    size="xs"
                    placement="right"
                    icon={<SioIcon icon="fa fa-circle-xmark"/>}
                />
            </div>
            <PanelGroup header="User List" accordion bordered size="sm">
                {teasers?.length > 0 && (
                    <Panel header="Einblicke">
                        <List>
                            {teasers.map((teaser, index) => (
                                <List.Item key={index} index={index}>
                                    {teaser.de}
                                </List.Item>
                            ))}
                        </List>
                    </Panel>
                )}
                {description?.de.length > 0 && (
                    <Panel header="Beschreibung">
                        <p
                            dangerouslySetInnerHTML={{
                                __html: description?.de,
                            }}
                        ></p>
                    </Panel>
                )}
                {images && images?.length > 0 && (
                    <Panel header="Bilder">
                        {images?.map((image, index) => {
                            return (
                                <span
                                    className="preview-view-image__container"
                                    key={index}
                                >
                                    <img
                                        className="preview-view-image"
                                        src={image?.file?.previewUrl
                                            ?.replace("$width", "150")
                                            ?.replace("$height", "150")}
                                    />
                                </span>
                            );
                        })}
                    </Panel>
                )}
                {rating && (
                    <Panel header="Bewertung">
                        <div
                            dangerouslySetInnerHTML={{
                                __html: holidayCheck.getPopoverContent(rating),
                            }}
                        ></div>
                    </Panel>
                )}
            </PanelGroup>
        </>
    );
};
