import {
  InputGroup,
  Input,
  Checkbox,
  SelectPicker,
  InputNumber,
} from "rsuite";
import {RADIUS_PICKER_VALUES} from "../../constants/index";
import {ChoiceElement} from "../../../../choice/react-choice-element";
import {GoogleOfferCityAutoComplete} from "../offer-city-autocomplete";
import {withGMapOptions} from "../../helper/gmap-context";
import {Foldable} from "../offer-foldable";
import SioIcon from "../../../../icon/rsuite-icon-font/SioIcon";

const CityAutoComplete = withGMapOptions(GoogleOfferCityAutoComplete);

export const RoomTabContent = (
  {
    filters,
    searchFreeText,
    setSearchFreeText,
    setFilter,
    isDefaultOccupancy,
    setIsDefaultOccupancy,
    isTwinPosition,
    setIsTwinPosition,
    isNoPrice,
    setNoPrice,
    sortOptions,
    minHotelCategory,
    minFare,
  }) => (
  <>
    <div className="col-xs-12 col-lg-6 col-small-padding">
      <div className="form-group">
        <label>Ort</label>
        <CityAutoComplete
          onSelect={(value) => {
            setFilter("city", value ? value : null);
          }}
          value={filters.city ?? ""}
        />
      </div>
    </div>
    <div className="col-xs-12 col-lg-6 col-small-padding">
      <div className="form-group radius-picker">
        <label>Radius</label>
        <SelectPicker
          cleanable={false}
          data={RADIUS_PICKER_VALUES}
          value={filters.radius}
          onChange={(value) => {
            setFilter("radius", value ? value : null);
          }}
          className="select-picker-input"
        />
      </div>
    </div>
    <div className="col-xs-12 col-small-padding">
      <div className="form-group">
        <label>SRG-Kategorie mind.</label>
        <ChoiceElement
          config={{
            modelId: "service-reisen/srg-category",
          }}
          preselected={minHotelCategory}
          value={filters.srgCategory ?? ""}
          placeholder="Wähle..."
          onChange={(value) => {
            setFilter("srgCategory", value ? {$eq: value} : null);
          }}
        />
      </div>
    </div>
    <div className="form-group col-xs-12 col-lg-8 col-small-padding">
      <InputGroup inside className="offer-search-parameters_input">
        <Input
          value={searchFreeText}
          onChange={setSearchFreeText}
          placeholder={"Suchbegriff"}
        />
        <InputGroup.Button>
          <SioIcon icon="fa fa-search"/>
        </InputGroup.Button>
      </InputGroup>
    </div>
    <div className="form-group col-xs-12 col-lg-4 col-small-padding">
      <SelectPicker
        data={sortOptions}
        cleanable={false}
        value={filters.sort}
        searchable={sortOptions.length > 10}
        onChange={(value) => {
          setFilter("sort", value ? value : null);
        }}
        className="select-picker-input"
      />
    </div>
    <Foldable>
      <div className="col-xs-12"/>
      <div className="col-xs-12 form-group">
        <label>Dauer in Nächten</label>
        <InputGroup className="offer-planning__number-picker">
          <InputNumber
            className="offer-planning__number-picker__input"
            value={filters.duration ?? 1}
            onChange={(value) => setFilter("duration", Number(value))}
            max={999999}
            min={1}
          />
        </InputGroup>
      </div>
      <div className="col-xs-12 form-group">
        <ChoiceElement
          config={{
            modelId: "tourism-accommodation/hotel-equipment",
            multiple: true,
          }}
          placeholder="Hotelausstattung"
          onChange={(value) => {
            setFilter("equipments", value ? {$in: value} : null);
          }}
        />
      </div>
      <div className="col-xs-6">
        <Checkbox
          checked={isDefaultOccupancy}
          onChange={(value, checked) => setIsDefaultOccupancy(checked)}
        >
          Relevant für Standard Kalkulation
        </Checkbox>
        <Checkbox
          checked={isTwinPosition}
          onChange={(value, checked) => setIsTwinPosition(checked)}
        >
          Zwillingsposition
        </Checkbox>
        <Checkbox
          checked={isNoPrice}
          onChange={(value, checked) => setNoPrice(checked)}
        >
          Ergebnisse ohne Preis anzeigen
        </Checkbox>
      </div>
      <div className="col-xs-6">
        <ChoiceElement
          config={{
            modelId: "tourism-room/fare",
          }}
          preselected={minFare}
          value={filters.fare ?? ""}
          placeholder="Verpflegung"
          onChange={(value) => {
            setFilter("fare", value ? {$eq: value} : null);
          }}
        />
      </div>
    </Foldable>
  </>
);
