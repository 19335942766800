import {
  InputGroup,
  Input,
  Checkbox,
  SelectPicker,
  InputNumber,
} from "rsuite";
import {RADIUS_PICKER_VALUES} from "../../constants/index";
import {ChoiceElement} from "../../../../choice/react-choice-element";
import {GoogleOfferCityAutoComplete} from "../offer-city-autocomplete";
import {withGMapOptions} from "../../helper/gmap-context";
import {Foldable} from "../offer-foldable";
import SioIcon from "../../../../icon/rsuite-icon-font/SioIcon";

const CityAutoComplete = withGMapOptions(GoogleOfferCityAutoComplete);

export const ItineraryTabContent = (
  {
    filters,
    searchFreeText,
    setSearchFreeText,
    setFilter,
    isDefaultOccupancy,
    setIsDefaultOccupancy,
    sortOptions,
  }) => (
  <>
    <div className="col-xs-12 col-lg-8 col-small-padding">
      <div className="form-group">
        <label>Ort</label>
        <CityAutoComplete
          onSelect={(value) => {
            setFilter("city", value ? value : null);
          }}
          value={filters.city ?? ""}
        />
      </div>
    </div>
    <div className="col-xs-12 col-lg-4 col-small-padding">
      <div className="form-group radius-picker">
        <label>Radius</label>
        <SelectPicker
          cleanable={false}
          data={RADIUS_PICKER_VALUES}
          value={filters.radius}
          onChange={(value) => {
            setFilter("radius", value ? value : null);
          }}
          className="select-picker-input"
        />
      </div>
    </div>
    <div className="form-group col-xs-12 col-lg-8 col-small-padding">
      <InputGroup inside className="offer-search-parameters_input">
        <Input
          value={searchFreeText}
          onChange={setSearchFreeText}
          placeholder={"Suchbegriff"}
        />
        <InputGroup.Button>
          <SioIcon icon="fa fa-search"/>
        </InputGroup.Button>
      </InputGroup>
    </div>
    <div className="form-group col-xs-12 col-lg-4 col-small-padding">
      <SelectPicker
        data={sortOptions}
        cleanable={false}
        value={filters.sort}
        searchable={sortOptions.length > 10}
        onChange={(value) => {
          setFilter("sort", value ? value : null);
        }}
        className="select-picker-input"
      />
    </div>
  </>
);
