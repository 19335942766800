import {
  InputGroup,
  Input,
  Icon,
  Checkbox,
  SelectPicker,
  InputNumber,
} from "rsuite";
import { RADIUS_PICKER_VALUES } from "../../constants/index";
import { ChoiceElement } from "../../../../choice/react-choice-element";
import { GoogleOfferCityAutoComplete } from "../offer-city-autocomplete";
import { DateSelect } from "../../../../form/rsuite-form";
import { withGMapOptions } from "../../helper/gmap-context";
import { Foldable } from "../offer-foldable";

const CityAutoComplete = withGMapOptions(GoogleOfferCityAutoComplete);

export const FlightTabContent = ({
  filters,
  searchFreeText,
  setSearchFreeText,
  setFilter,
  sortOptions,
}) => (
  <>
    <div className="col-xs-12 col-lg-6 col-small-padding">
      <div className="form-group">
        <label>Abflughafen</label>
        <ChoiceElement
          config={{
            modelId: "aviation/airport",
          }}
          placeholder="Wähle..."
          onChange={(value) => {
            setFilter("departureAirport", value ? { $eq: value } : null);
          }}
        />
      </div>
    </div>

    <div className="col-xs-12 col-lg-6 col-small-padding">
      <div className="form-group">
        <label>Zielflughafen</label>
        <ChoiceElement
          config={{
            modelId: "aviation/airport",
          }}
          placeholder="Wähle..."
          onChange={(value) => {
            setFilter("destinationAirport", value ? { $eq: value } : null);
          }}
        />
      </div>
    </div>

    <div className="col-xs-12 col-lg-6 col-small-padding">
      <div className="form-group">
        <label>Airlines</label>
        <ChoiceElement
          config={{
            modelId: "aviation/airline",
            multiple: true,
          }}
          placeholder="Wähle..."
          onChange={(value) => {
            setFilter("airlines", value ? { $in: value } : null);
          }}
        />
      </div>
    </div>

    <div className="col-xs-12 col-lg-6 col-small-padding">
      <div className="form-group">
        <label>Kabinenklasse</label>
        <ChoiceElement
          config={{
            set: "cabin-class",
          }}
          placeholder="Wähle..."
          onChange={(value) => {
            setFilter("cabinClasses", value ? { $eq: value } : null);
          }}
        />
      </div>
    </div>

    <Foldable>
      <div className="col-xs-12" />
      <div className="col-xs-6">
        <Checkbox
          className="col-xs-6"
          checked={!!filters["arrival"]}
          onChange={(_, checked) => setFilter("arrival", checked)}
        >
          Ankunft
        </Checkbox>
      </div>

      <div className="col-xs-6">
        <div className="form-group">
          <label>Anbieter</label>
          <ChoiceElement
            config={{
              set: "flight-provider",
              multiple: true,
            }}
            placeholder="Wähle..."
            onChange={(value) => {
              setFilter("flightProvider", value ? { $in: value } : null);
            }}
          />
        </div>
      </div>

      <div className="col-xs-6">
        <Checkbox
          className="col-xs-6"
          checked={!!filters["nonstop"]}
          onChange={(_, checked) => setFilter("nonstop", checked)}
        >
          Nur Nonstop
        </Checkbox>
      </div>

      <div className="col-xs-6">
        <Checkbox
          className="col-xs-6"
          checked={!!filters["includedBaggage"]}
          onChange={(_, checked) => setFilter("includedBaggage", checked)}
        >
          Freigepäck inklusive
        </Checkbox>
      </div>

    </Foldable>
  </>
);
